import * as React from 'react';
import { useRef, useState, lazy } from "react";
import { useForm, type FieldError } from 'react-hook-form';
import Spinner from "../components/spinner";
import { clsx } from 'clsx';
import { getEnv } from "../helpers/env.ts";

interface ContactFormValues {
	name: string,
	email: string,
	phone: string,
	preferred: string,

	updates: boolean,
	consent: boolean,
}

type Props = {
	content?: any
}

const DevTool = lazy(() => import('../components/hookFormDevToolsWrapper'));

export default function Callback({content = null, ...otherProps}: Props) {
	const [isSent, setIsSent] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		reset,
		control,
	} = useForm<ContactFormValues>();

	const form = useRef(null);

	function inputRadioClass(error: boolean|string|FieldError = false) {
		return clsx('border rounded', (error ? '!border-red-400' : 'border-transparent'));
	}

	function inputClass(error: boolean|string|FieldError = false) {
		return clsx('border text-black', error && '!border-red-400');
	}

	return (<>
		<form ref={form} onSubmit={handleSubmit(onSubmit)} className="relative">
			{getEnv('PUBLIC_DUMP_ALLOWED') === "yes" &&
				<DevTool control={control} placement="top-right" styles={{button: { position: "absolute", zIndex: "auto" }}}/>
			}

			<div className="flex flex-wrap overflow-hidden max-w-screen-2xl -mx-12 text-lg pb-3">
				<div className="w-full px-12 mb-4">
					<label className="mb-2 inline-block font-serif" htmlFor="name">NAME *</label><br/>
					<input
						//defaultValue="Hallo"
						className={clsx('w-full border-grey-300', inputClass(errors.name))}
						type="text"
						id="name" name="name"
						size={20}
						{...register("name", {required: true})}
					/>
				</div>

				<div className="w-full px-12 mb-4">
					<label className="mb-2 inline-block font-serif" htmlFor="email">EMAIL</label><br/>
					<input
						//defaultValue="tst@gfdgsd.pl"
						className={clsx('w-full border-grey-300', inputClass(errors.email))}
						type="email"
						id="email" name="email"
						size={20}
						{...register("email", {required: false, pattern: /^\S+@\S+$/i})}
					/>
				</div>

				<div className="w-full px-12 mb-4">
					<label className="mb-2 inline-block font-serif" htmlFor="phone">PHONE</label><br/>
					<input
						//defaultValue="789789789"
						className={clsx('w-full border-grey-300', inputClass(errors.phone))}
						type="tel"
						id="phone" name="phone"
						size={20}
						{...register("phone", {required: false, maxLength: 12})}
					/>
				</div>

				<div className="w-full px-12 mb-4">
					<label className="mb-2 inline-block font-serif" htmlFor="preferred">PREFERRED TIME TO CALL *</label><br/>
					<select
						//defaultValue="789789789"
						className={clsx('w-full border-grey-300', inputClass(errors.phone))}
						id="preferred" name="preferred"
						{...register("preferred", {required: true})}
					>
						<option value={1}>– Please choose an option –</option>
						<option value={2}>8-10</option>
					</select>
				</div>

				<div className="w-full px-12 mt-4 mb-4">
					<div className={clsx('border px-4 -mx-4 flex py-1 align-middle', inputRadioClass(errors.updates))}>
						<input
							className="!mb-0 !focus:outline-0"
							id="updates" name="updates"
							type="checkbox"
							//checked
							{...register("updates")}
						/>
						<label className="mb-0 text-base inline-block ml-2" htmlFor="updates">
							I am happy to receive email updates
						</label>
					</div>
				</div>

				<div className="w-full px-12 mb-4">
					<div className={clsx('border px-4 -mx-4 flex py-1 align-middle', inputRadioClass(errors.consent))}>
						<input
							className="!mb-0 !focus:outline-0"
							id="consent" name="consent"
							type="checkbox"
							//checked
							{...register("consent", {required: true})}
						/>
						<label className="mb-0 text-base inline-block ml-2" htmlFor="consent">
							I consent to Bluespires collecting this information.
							For further details please visit our
							<a href="#" className="text-white underline hover:text-grey-300">privacy policy</a>.
						</label>
					</div>
				</div>
			</div>

			<button
				disabled={isSubmitting}
				className={clsx("w-full sm:w-auto min-w-[180px] mr-6 blueDark", isSubmitting ? '!bg-grey' : '!bg-red')}
				type="submit"
			>
				{isSubmitting ? 'SENDING' : 'SUBMIT'}
				{isSubmitting && <Spinner className="!w-4 !h-4"/>}
			</button>
			{isSent && <span onClick={() => setIsSent(false)}>We will respond to the inquiry as soon as possible.</span>}
		</form>
	</>);

	function resetForm() {
		reset({
			subject: '',
			name: '',
			email: '',
			phone: '',
			company: '',
			message: '',
			consent: null,
		});
	}

	function onSubmit() {
		setIsSent(false);

		const data = new FormData(form.current);

		return fetch('/process/contact', {
			method: "POST",
			body: data
		}).then(() => {
			setIsSent(true);
			resetForm();
		});
	}
}
