import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import DialogStyled from "../components/dialogStyled.tsx";
import Callback from "../contact/callback.tsx";
import useClickOutside from "../helpers/useClickOutside";
import useDetectSticky from "../helpers/useDetectSticky";
import type { MenuData } from "../rest.interface";
import MenuItem, { filterMenuItemsByParent } from "./menuItem";
import clsx from 'clsx';

interface Props {
	menu: MenuData,
	pathname: string,
}

const Header = ({menu, pathname, ...otherProps}: Props) => {
	const refHeader = useRef<HTMLHeadingElement>(null);
	const refMobileMenu = useRef<HTMLDivElement>(null);
	const isSticky = useDetectSticky(refHeader, {threshold: [1]});
	const [opened, setOpened] = useState<boolean>(false);
	useClickOutside(refMobileMenu, () => setOpened(false));
	const [showModal, setShowModal] = useState<boolean>(true);

	useEffect(() => {
		setOpened(false);
	}, [pathname]);

	let logo = <h2 className={clsx("logo m-0 max-lg:w-[189px] max-lg:h-[43px] transition-all", isSticky && 'small')}>Blue spires</h2>;
	if (pathname !== '/') {
		logo = <a href="/">{logo}</a>;
	}

	const rootMenuItems = filterMenuItemsByParent(menu, null);

	return (<>
		{/*<Dump {...menu}/>*/}

		<div
			id="headerWrapper"
			className={clsx("-top-[1px] bg-white/75 bg backdrop-blur-md sticky z-10 px-4 md:px-10 2xl:px-20 transition-all ease-mine duration-500", isSticky && "shadow")}
			{...otherProps}
			ref={refHeader}
		>
			<header
				className={clsx("max-w-screen-2xl mx-auto text-center relative px-4 md:px-10 xl:px-20", !isSticky && "border-b border-grey-400")}>
				<div className={clsx("flex flex-wrap items-center justify-between gap-x-4", isSticky ? "py-4" : 'pt-4 max-lg:pb-4')}>
					{logo}

					<div className={clsx("hidden xl:inline-block", isSticky && "order-3")}>
						<a href="/transfer-to-us" className="button mr-4 small outlined blue">
							TRANSFER TO US
						</a>
						<a href="#callback" className="button small" onClick={(event) => { event.preventDefault(); setShowModal(true); }}>
							REQUEST A CALLBACK
						</a>
					</div>

					<a href="#mobileMenu" className={clsx("iconHamburger block lg:hidden", opened && "iconHamburgerOpened")} aria-label="Menu" title="Menu"
					   onClick={() => setOpened(!opened)}>
						<span/>
						<span/>
						<span/>
					</a>

					<div className={clsx("hidden lg:flex", !isSticky ? "w-full items-center justify-between mt-6" : "order-2 self-center")}>
						<menu className="mainMenu">
							{rootMenuItems && rootMenuItems.map((item, index) => {
								return <MenuItem menu={menu} item={item} key={'item' + index} pathname={pathname} className={clsx(isSticky && 'isSticky')}/>;
							})}
						</menu>

						{!isSticky && <div className="tracking-widest text-primary-400 text-base hidden lg:block">
							CALL US: <b>01865 959160</b>
						</div>}
					</div>
				</div>
			</header>
		</div>

		<div className={clsx(
			"fixed top-0 right-0 z-40 h-full w-full transition-all duration-300",
			opened ? 'bg-white/30 visible backdrop-blur-sm' : 'backdrop-blur-none bg-white/0 invisible'
		)}/>

		<div className={clsx(
			"fixed top-0 right-0 z-50 h-full w-full overflow-y-auto overscroll-y-none transition duration-500",
			opened ? 'translate-x-0' : 'translate-x-full'
		)}>
			<div className="float-right relative min-h-full w-[80%] bg-white p-8 sm:p-12 shadow-xl" ref={refMobileMenu}>
				<a href="#" className="iconOpenClose iconOpened absolute top-2 right-6" onClick={() => setOpened(false)} title="X"></a>
				<menu className="mobileMenu font-medium">
					{rootMenuItems && rootMenuItems.map((item, index) => {
						return <MenuItem menu={menu} item={item} key={'item' + index} pathname={pathname}/>;
					})}
				</menu>
			</div>
		</div>
		{/*<Dump value={isSticky}/>*/}

		<DialogStyled
			isOpen={showModal}
			onClose={() => { setShowModal(false); }}
			classesSize={"max-w-screen-md max-h-[97vh]"}
			zIndex={50}
		>
			<div className="w-full h-full bg-grey-100 py-12 px-24 overflow-hidden max-w-screen-lg">
				<div className="text-center">
					<img src="/images/logo/bluespires-logo-small.png" alt="Bluespires" width="211" height="48"
					     className="inline-block mb-8"/>
					<br/>
					<h2 className="font-normal text-primary-400">Request a callback</h2>
				</div>
				<Callback/>
			</div>
		</DialogStyled>
	</>);
}

export default Header;
