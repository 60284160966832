import * as React from 'react';
import { Dialog, DialogPanel, DialogBackdrop } from '@headlessui/react'
import DeleteButton from './deleteButton';
import { clsx } from 'clsx';

type Props = Readonly<{
	isOpen: boolean,
	onClose: Function,
	children?: React.ReactNode
	zIndex?: number,
	classesSize?: string
}>;

const DialogStyled = ({
	isOpen,
	onClose,
	children,
	zIndex = 40,
	classesSize = "min-w-[66vw] max-w-[90vw] min-h-[66vh] max-h-[97vh]"
}: Props) => {

	return (
		<Dialog open={isOpen} onClose={() => onClose()} className={clsx("relative", "z-" + zIndex)}>
			<DialogBackdrop className="fixed inset-0 bg-overlay"/>

			<div className="fixed inset-0 flex w-screen items-center justify-center overflow-y-auto">
				<DialogPanel className={clsx("bg-white shadow-lg overflow-auto relative", classesSize)}>
					<DeleteButton onClick={() => onClose()} className="absolute right-2 top-2"/>
					{children}
				</DialogPanel>
			</div>
		</Dialog>
	);
}

export default DialogStyled;

