import * as React from 'react';
import { clsx } from 'clsx';
import IconFontAwesome from "./iconFontAwesome";

interface Props extends React.ComponentPropsWithoutRef<"span"> {
	className?: string,
	iconClassName?: string
}

const DeleteButton = ({className = '', iconClassName = '', ...otherProps}: Props) => {
	return (<>
		<span className={clsx(className, "cursor-pointer text-grey-500 leading-none")} {...otherProps}>
			<IconFontAwesome className={clsx(iconClassName, "icon-times text-2xl hover:text-red-300")} title="X"/>
		</span>
	</>);
}

export default DeleteButton;
