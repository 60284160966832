import * as React from 'react';
import { clsx } from 'clsx';

type Props = Readonly<React.ComponentPropsWithoutRef<"i"> & {}>

const IconFontAwesome = ({className = '',  ...otherProps}: Props) => {
	return <i className={clsx("text-grey-500 icon-before fa-icon", className)} {...otherProps}/>
}

export default IconFontAwesome;
