import * as React from 'react';
import { clsx } from 'clsx';
import type { MenuData, MenuItemData } from "../rest.interface";
import MenuItemTitle from "./menuItemTitle";
import { useHoverIntent } from "react-use-hoverintent";

interface MenuItemProps extends React.ComponentPropsWithoutRef<"li"> {
	menu: MenuData,
	item: MenuItemData,
	depth?: number,
	pathname: string,
}

export function filterMenuItemsByParent(menu: MenuData, parentId: number) {
	return menu?.items.filter((menuItem: any) => {
		return menuItem.parentId === parentId;
	});
}

const MenuItem = ({menu, item, pathname, depth = 0, className = "", ...otherProps}: MenuItemProps) => {
	const subMenuItems = filterMenuItemsByParent(menu, item.nodeId);
	const [isHovering, intentRef] = useHoverIntent({
		timeout: 100,
	});

	return (<>
		<li ref={intentRef} className={clsx(subMenuItems.length && "withSubMenu", isHovering && "intent", className)} {...otherProps}>
			<MenuItemTitle item={item} subMenuItems={subMenuItems} depth={depth} pathname={pathname}/>

			{subMenuItems.length > 0 && <ul>{
				subMenuItems.map((subItem, index) => {
					return <MenuItem key={"subItem" + index} menu={menu} item={subItem} depth={depth + 1} pathname={pathname}/>
				})
			}</ul>}
		</li>
	</>);
}

export default MenuItem;
