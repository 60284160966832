import * as React from 'react';
import { clsx } from 'clsx';
import IconFontAwesome from "../components/iconFontAwesome";
import type { MenuItemData } from "../rest.interface";

interface MenuItemTitleProps {
	item: MenuItemData,
	subMenuItems: MenuItemData[],
	depth?: number,
	pathname: string,
}

function getUrlForMenuItem(item: MenuItemData) {
	let toReturn = '';

	switch (item.type) {
		case 'Url':
			toReturn = item?.url;
			break;
		case 'Page':
			toReturn = item?.page?.absoluteUrl;
			break;
	}

	if (!toReturn.startsWith('/') && !toReturn.startsWith('#')) {
		toReturn = '/' + toReturn;
	}

	return toReturn;
}

const MenuItemTitle = ({item, subMenuItems, pathname, depth = 0}: MenuItemTitleProps) => {
	const url = getUrlForMenuItem(item);

	const name = <>
		{item.title}
		{subMenuItems.length > 0 && <>
			&nbsp;
			<IconFontAwesome className={clsx(depth === 0 ? "icon-chevron-down" : 'icon-chevron-right', "text-sm color-grey-500")}/>
		</>}
	</>

	return (<>
		{item.type === 'Label' ?
			<span>{name}</span> :
			<a href={url} className={clsx(pathname === url && "current")}>{name}</a>
		}
	</>);
}

export default MenuItemTitle;
